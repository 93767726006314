import { StatusCategory } from '@cycle-app/graphql-codegen';
import { ComponentPropsWithRef, forwardRef } from 'react';
import { twJoin } from 'tailwind-merge';

import { GroupCanceledIcon, GroupCompletedIcon, GroupStartedIcon, GroupNotStartedIcon } from '../../icons';

export type GroupStatusIconProps = ComponentPropsWithRef<'div'> & {
  category: StatusCategory;
  withBackground?: boolean;
  size?: 16 | 20;
};

export const StatusIcon = forwardRef<HTMLDivElement, GroupStatusIconProps>(({
  category, withBackground, size = 20, className, ...props
}, ref) => {
  const iconSize = size === 20 ? 14 : 12;
  const canceled = category === StatusCategory.Canceled;
  const completed = category === StatusCategory.Completed;
  const notStarted = category === StatusCategory.NotStarted;
  const started = category === StatusCategory.Started;
  return (
    <div
      ref={ref}
      className={twJoin(
        'inline-flex items-center justify-center',
        withBackground && 'rounded-[4px]',
        withBackground && size === 16 && 'size-4',
        withBackground && size === 20 && 'size-5',
        canceled && 'text-red-800 dark:text-red-400',
        canceled && withBackground && 'bg-orangered-200 dark:bg-[#b844444d]',
        completed && 'text-green-800 dark:text-[#90CA91]',
        completed && withBackground && 'bg-[#E1F3E5] dark:bg-[#5798584d]',
        notStarted && 'text-grey-800 dark:text-grey-350',
        notStarted && withBackground && 'bg-grey-100 dark:bg-grey-850',
        started && 'text-blue-800 dark:text-blue-400',
        started && withBackground && 'bg-blue-200 dark:bg-[#4058f24d]',
        className,
      )}
      {...props}
    >
      {canceled && <GroupCanceledIcon size={iconSize} />}
      {completed && <GroupCompletedIcon size={iconSize} />}
      {notStarted && <GroupNotStartedIcon size={iconSize} />}
      {started && <GroupStartedIcon size={iconSize} />}
    </div>
  );
});
